<template>
  <div>
    <b-row>
      <b-col class="col-12 text-right">
        <b-button
          v-if="getUserData().is_superuser"
          :disabled="exportable.length < 1"
          variant="export-button-table"
          class="mb-1 export-button-table mr-1"
          @click="exportPaymentData()"
        >
          <feather-icon icon="DollarSignIcon"></feather-icon>
          {{$t('campaigns.downloadPaymentInfo')}}
        </b-button>

        <b-button
          :disabled="exportable.length < 1"
          variant="export-button-table"
          class="mb-1 export-button-table"
          @click="exportSelected()"
        >
          <feather-icon icon="ShareIcon"></feather-icon>
          {{$t('campaigns.export')}}
        </b-button>
        <b-button
          variant="danger"
          class="mb-1 ml-1"
          @click="bulkRemove()"
          :disabled="exportable.length < 1"
        >
          {{$t('delete')}}
        </b-button>
      </b-col>
      <b-col class="col-12">
        <b-card>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'type_influencer', type: 'asc'},
              multipleColumns: false,
            }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            @on-selected-rows-change="selectionChanged"
            class="vgt-table-managing"
          >
            <template slot="table-column" slot-scope="props">
              <div v-if="props.column.field === 'name'" class="cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'type_influencer'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'network'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'scope'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
                <div v-if="props.column.field === 'published_contents'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'status'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'status_influencer'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'has_payment_data'" class="d-block text-center cursor-pointer">
                {{ $t(props.column.label) }}
                <b-icon icon="chevron-bar-expand" class="float-right"/>
              </div>
              <div v-if="props.column.field === 'button_add_contente'" class="d-block text-center cursor-pointer">
                ""
              </div>
              <span v-if="props.column.field === 'actions'" class="d-block text-center">
                {{ $t(props.column.label) }}
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'name'" class="text-nowrap row-selected-table">
                <b-media vertical-align="center" class="d-flex align-items-center">
                  <template #aside>
                    <div class="container-avatar-table">
                      <b-avatar
                        size="md"
                        :src="props.row.avatar"
                        :text="props.row.username[0]"
                        variant="light-primary"
                      />
                      <b-avatar class="container-avatar-table-icon" variant="light" size="20">
                        <b-img  :src="getIconWhite(props.row.network)" :class="getIconColor(props.row.network, false)" fluid/>
                      </b-avatar>
                    </div>
                  </template>
                    <span>{{ getUsername(props.row) }}
                    <image-verified v-if="props.row.is_verified" class="iconCheck"/>
                    </span>
                </b-media>
              </span>
              <span
                v-else-if="props.column.field === 'type_influencer'"
                class="text-nowrap d-block text-center p-2"
              >
                <span class="text-nowrap">{{ $t(getTypeInfluencerTraduction(props.row.type_influencer)) }}</span>
              </span>
              <span
                v-else-if="props.column.field === 'network'"
                class="text-nowrap d-block text-center"
              >
                <a class="text-nowrap name-link" 
                  :href="getURL(props.row.network, props.row.username, (props.row.network === 'youtube') ? true : false)" 
                  target="_blank"
                > <b-avatar variant="ligth" size="20">
                  <b-img class="icon-squad-network" :src="getIconWhite(props.row.network)" :class="getIconColor(props.row.network, false)" fluid/>
                </b-avatar>
                 {{
                    capitalize(props.row.network)
                  }}</a>
              </span>
              <span
                v-else-if="props.column.field === 'scope'"
                class="text-nowrap d-block text-center"
              >
                <span class="text-nowrap">{{ getFormat(props.row.scope) }}</span>
              </span>
              <span
                v-else-if="props.column.field === 'published_contents'"
                class="text-nowrap text-center d-block"
              >
                <span class="text-nowrap">{{ props.row.published_contents }}</span>
              </span>
              <span v-else-if="props.column.field === 'status'" class="d-block text-center">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ $t(getStatus(props.row.status)) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'status_influencer'" class="d-block text-center">
                <b-badge :variant="statusVariant(props.row.status_influencer)">
                  {{ $t(getStatus(props.row.status_influencer)) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'has_payment_data'" class="d-block text-center">
                <b-badge :variant="statusVariantPaymentData(props.row.has_payment_data)">
                  {{props.row.has_payment_data ? $t('campaigns.yes') : 'No'}}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'button_add'" class="d-flex justify-content-center">
                <b-button size="sm" @click="addContent(props.row)" variant="blue-button" class="float-right mb-1 blue-button">
                  {{$t('campaigns.add_content')}}
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                  />
                </b-button>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'actions'" class="d-block text-center">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    dropright
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="copyLink(props.row)">
                      <feather-icon icon="LinkIcon" class="mr-50" />
                      <span>{{$t('campaigns.copy_link')}}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteCreator(props.row.uuid)">
                      <feather-icon icon="XCircleIcon" class="mr-50" />
                      <span>{{$t('campaigns.delete_creator')}}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> {{$t('campaigns.showing_to')}} </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    {{$t('campaigns.of')}} {{ props.total }} {{$t('campaigns.entries')}}
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>

    <form-add-content :show_form_metrics="false" @contentAdded="contentAdded" :campaign_influencer="campaign_influencer_to_add" :show_add_content="show_add_content" :content_published="content_published" @update_header="update_header" :key="key_modal_add"/>

    <b-sidebar
      right
      shadow
      v-model="show_side"
    >
      <div class="px-3">
        <div class="d-flex justify-content-between mb-2">
          <b-media vertical-align="center" class="d-flex justify-content-center align-items-center flex-wrap mb-1 col-6">
            <template #aside>
              <div class="container-avatar-table col-12">
                <b-avatar
                  size="lg"
                  :src="creator.avatar"
                  :text="creator.username"
                  variant="light-primary"
                />
                <b-avatar class="container-avatar-table-icon sidebar-icon">
                  <b-img
                    :src="`${getIconWhite(creator.network)}`" :class="getIconColor(creator.network)" fluid></b-img>
                </b-avatar>
              </div>
            </template>

            <div class="col-12">
              <a class="d-block font-weight-bold h4 mb-0 col-12 text-center"
                  :href="getURL(creator.network, creator.username, (creator.network === 'youtube') ? true : false)"
                  target="_blank"
              >
                  {{ getUsername(creator) }}
              </a>
            </div>

          </b-media>  
          <b-col class="d-flex flex-wrap justify-content-center col-6 mb-2 mt-1">
            <b-avatar rounded="lg" :variant="statusVariant(creator.status)">
              <feather-icon
                size="18"
                icon="LikeIcon"
              />
            </b-avatar>    
            <span class="d-block h5 col-12 text-center mt-1">{{$t(`campaigns.${creator.status}`)}}</span>
            <span class="text-muted d-block col-12 text-center">{{$t('campaigns.status')}}</span>
          </b-col>
        </div>

        <b-row>
          <b-col class="col-6">
            <span class="d-block h5 col-12 text-center">{{getTypeInfluencer(creator.type_influencer)}}</span>
            <span class="text-muted d-block col-12 text-center">{{$t('campaigns.type_influencer')}}</span>
          </b-col>
          
          <b-col class="col-6">
            <span class="d-block h5 col-12 text-center">{{creator.published_contents}}</span>
            <span class="text-muted d-block col-12 text-center">{{$t('campaigns.published_contents')}}</span>
          </b-col>

          <b-col class="col-6 mt-2">
            <span class="d-block h5 col-12 text-center">{{$t('generalLeads.date')}}</span>
            <span class="text-muted d-block col-12 text-center">{{shortDate(creator.date)}}</span>
          </b-col>
        </b-row>
        <hr />
        <b-button id="tooltip-extra-content" variant="flat-primary" class="btn-icon rounded-circle add-content-sidebar-button" @click="addContent(creator)">
          <feather-icon icon="PlusCircleIcon" size="20" class=""/>
        </b-button>
        <b-tooltip target="tooltip-extra-content">
          <span>{{$t('campaigns.extra_content')}}</span>        
        </b-tooltip>

        <h5 class="text-center">{{$t('campaigns.edit_creator')}}</h5>
        <validation-observer #default="{ invalid }">
          <b-row class="w-100 mt-2">
            <b-col cols="12">
              <b-row>
                
                <b-col :class="`col-12 mb-1`">
                  <validation-provider rules="required">
                    <b-form-group 
                      :label="$t('campaigns.estimated_cost')"
                      :description="''"
                    >
                      <b-input-group class="m-auto">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="DollarSignIcon" />
                        </b-input-group-prepend>
                        <input-number-comma :price="creator.estimated_cost" identifier="estimated_cost" :key="change_sidebar" @changeValue="changeValue" :placeholder="$t('campaigns.estimated_cost')"></input-number-comma>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col :class="`col-12 mb-1`">
                  <validation-provider rules="required">
                    <b-form-group
                      :label="$t('campaigns.agreed_cost')"
                      :description="''"                    
                    >
                      <b-input-group class="m-auto">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="DollarSignIcon" />
                        </b-input-group-prepend>
                        <input-number-comma :price="creator.agreed_cost" identifier="agreed_cost" :key="change_sidebar" @changeValue="changeValue" :placeholder="$t('campaigns.agreed_cost')"></input-number-comma>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col :class="`col-12 mb-1`">
                  <validation-provider rules="required">
                    <b-form-group
                      :label="$t('scope_number')"
                      :description="''"                    
                    >
                      <b-input-group class="m-auto">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="ActivityIcon" />
                        </b-input-group-prepend>
                        <input-number-comma :price="creator.scope" identifier="scope" :key="change_sidebar" @changeValue="changeValue" :placeholder="$t('scope_number')"></input-number-comma>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center mt-1">
            <b-button
              variant="success success-button-invitations"
              class="success-button-invitations"
              @click="updateCreator(creator.uuid)"
              :disabled="invalid"
            >
              {{$t('dashboard.save')}}
            </b-button>
          </b-row>
        </validation-observer>

      </div>
    </b-sidebar>
  </div>
</template>

<script>

import {
  BImg,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination,
  BSidebar,
  BMedia,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormGroup,
  BTooltip
} from "bootstrap-vue";
import service from "@/services/campaign";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { VueGoodTable } from "vue-good-table";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import manage_campaign from '@/services/manage_campaign';
import FormAddContent from "./FormAddContent.vue";
import inputNumberComma from "../inputs/inputNumberComma.vue";
import ImageVerified from '@/views/components/image/ImageVerified.vue';
import { getIconWhite, getIconColor } from '@/libs/utils/icons';
import { getUserData, hasPermission } from '@/libs/utils/user';
import { getTypeInfluencerTraduction, getTypeInfluencer, getMotivationalPhrase, getTypeInfluencerNumber, getImageInfluencer } from '@/libs/utils/others';
import { getURL } from '@/libs/utils/urls'
import { capitalize, getFormat } from '@/libs/utils/formats';
import { statusVariant, getStatus, statusVariantPaymentData } from '@/libs/utils/series_graphs';

export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    ImageVerified,
    BImg,
    VueGoodTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BSidebar,
    BMedia,
    BInputGroup,
    BInputGroupPrepend,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BFormGroup,
    FormAddContent,
    inputNumberComma,
    BTooltip,
  },
  data() {
    return {
      hasPermission,
      getImageInfluencer,
      getTypeInfluencerNumber,
      getMotivationalPhrase,
      getTypeInfluencer,
      statusVariantPaymentData,
      getStatus,
      getIconColor,
      getIconWhite,
      statusVariant,
      getFormat,
      capitalize,
      getURL,
      getTypeInfluencerTraduction,
      getUserData,
      content_published: false,
      creator: {},
      show_side: false,
      local: window.location.origin,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "lists.influencer",
          field: "name",
        },
        {
          label: "campaigns.type_influencer",
          field: "type_influencer",
          sortable: true,
          sortFn: this.sortFn,
        },
        {
          label: "settings.social.network",
          field: "network",
        },
        {
          label: "campaigns.followers",
          field: "scope",
          type: 'number'
        },
        {
          label: "campaigns.published_contents",
          field: "published_contents",
        },
        {
          label: "campaigns.status",
          field: "status",
        },
        {
          label: "campaigns.status_influencer",
          field: "status_influencer",
        },
        {
          label: "campaigns.has_payment_data",
          field: "has_payment_data",
        },
        {
          label: "button_add_contente",
          field: "button_add",
        },
        {
          label: "campaigns.actions",
          field: "actions",
        },
      ],
      rows: [],
      info: [],
      exportable: [],
      show_add_content: false,
      key_modal_add: false,
      campaign_influencer_to_add: '',
      change_sidebar: false,
      interval_loading: null
    };
  },
  created() {
    this.$vs.loading({
      type: 'sound',
      background: 'rgba(255, 255, 255, 1)',
      text: getMotivationalPhrase(),
    });

    this.interval_loading = setInterval(() => {
      const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

      if (prev_loading) {
        prev_loading.classList.add('fade-out');
        setTimeout(function() {
          prev_loading.remove()
        }, 500);

        this.$vs.loading({
          type: 'sound',
          background: 'rgba(255, 255, 255, 1)',
          text: getMotivationalPhrase(),
        });
      }
    }, 5000);
    service.getCampaignManage(this.$route.params.uuid).then((response) => {
      this.info = response.results;
      this.rows = this.getProperlyRowsTable();
      setTimeout(() => {
        clearInterval(this.interval_loading)
        this.$vs.loading.close();
      }, 500);
    });
  },
  methods: {
    update_header(data) {
      this.$emit('update_header', data);
    },
    sortFn(x, y) {
      const first = getTypeInfluencerNumber(x)
      const second = getTypeInfluencerNumber(y)
      return (first < second ? -1 : (first > second ? 1 : 0));
    },
    bulkRemove() {
      const influencers_uuids = [];
      this.exportable.forEach(item => {
        influencers_uuids.push(item.uuid)
      })

      this.$swal({
        title: this.$t('campaigns.textConfirmCreators'),
        text: this.$t('campaigns.textCreators'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmCreators'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          service.influencerAction(this.$route.params.uuid, influencers_uuids, 'removed').then(() => {
            this.$swal({
              title: this.$t('campaigns.title_deleted_creator'),
              text: this.$t('campaigns.text_deleted_creator'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false,
              heightAuto: false
            })
            this.rows = this.rows.filter((item) => influencers_uuids.indexOf(item.uuid) < 0);
          })
        }
      })
    },
    contentAdded() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.getCampaignManage(this.$route.params.uuid).then((response) => {
        this.info = response.results;
        this.rows = this.getProperlyRowsTable();
        clearInterval(this.interval_loading)
        this.$vs.loading.close();
      });
    },
    downloadCsv(rows, name) {
      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = name;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    exportPaymentData() {
      const rows = [['Influencer', 'Red social', 'Alcance', 'Contenidos publicados', 'Estatus', 'Cuenta Nacional', 'Nombre de beneficiario', 'Banco', 'Cuenta', 'CLABE/ABA/SWIFT/BIC', 'Mail', 'Celular', 'Dirección', 'Ciudad', 'País']];
      this.exportable.forEach(data => {
        const username = data.network === 'youtube' ? data.name : data.username

        rows.push([
          username,
          capitalize(data.network),
          data.scope,
          data.published_contents,
          this.$t(getStatus(data.status)),
          data.payment_data ? (data.payment_data.is_national ? 'Sí' : 'No') : '-',
          data.payment_data ? data.payment_data.beneficiary_name : '-',
          data.payment_data ? data.payment_data.bank : '-',
          data.payment_data ? `${data.payment_data.account}` : '-',
          data.payment_data ? `${data.payment_data.clabe}` : '-',
          data.payment_data ? data.payment_data.email : '-',
          data.payment_data ? data.payment_data.telephone : '-',
          data.payment_data ? data.payment_data.address : '-',
          data.payment_data ? data.payment_data.city : '-',
          data.payment_data ? data.payment_data.country : '-',
        ])
      })
      this.downloadCsv(rows, "Información de pago.csv")
    },
    exportSelected() {
      const rows = [['Influencer', 'Tipo de influencer', 'Red social', 'Alcance', 'Contenidos publicados', 'Estatus']];

      this.exportable.forEach(data => {
        const username = data.network === 'youtube' ? data.name : data.username

        rows.push([
          username,
          getTypeInfluencer(data.type_influencer),
          capitalize(data.network),
          data.scope,
          data.published_contents,
          this.$t(getStatus(data.status))
        ])
      })

      this.downloadCsv(rows, "Influencers.csv")
    },
    selectionChanged(data) {
      this.exportable = data.selectedRows
    },
    onCellClick(data) {
      if (data.column.field !== 'actions') {
        this.show_side = true;
        this.creator = {...data.row};
        this.change_sidebar = !this.change_sidebar
      }
    },
    changeValue(new_value, key) {
      this.creator[key] = new_value
    },
    getProperlyRowsTable() {
      const new_array = [];
      this.info.forEach((item) => {
        new_array.push({
          uuid: item.uuid,
          name: item.influencer.name,
          avatar: getImageInfluencer(item.influencer),
          type_influencer: item.influencer.calculate_category,
          network: item.influencer.network.toLowerCase(),
          scope: item.scope,
          date: item.created_at,
          status: item.status,
          username: item.influencer.username,
          published_contents: this.getContents(item.content),
          agreed_cost: item.agreed_cost,
          estimated_cost: item.estimated_cost,
          is_verified: item.influencer.is_verified,
          payment_data: item.influencer.payment_data,
          has_payment_data: item.influencer.has_payment_data,
          status_influencer: item.status_influencer
        });
      });
      return new_array;
    },
    shortDate(date) {
      const newDate = new Date(date);
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return newDate.toLocaleDateString("es-ES", options);
    },
    getUsername(influencer) {
      if (influencer.network === "youtube") return influencer.name;
      return influencer.username;
    },
    copyLink(data) {
      const url = `${window.location.host}/external-new/details/${this.slug}/${data.network}/${data.username}`;
      this.$copyText(url).then(
        () => {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              props: {
                title: this.$t('campaigns.copied'),
                icon: "CopyIcon",
                text: this.$t('campaigns.clipboard'),
                variant: 'success'
              }
            })
          })
        },
        (e) => {
          console.log(e);
        }
      );
    },
    deleteCreator(uuid) {
      if (this.disabledUpdateCampaign()) return;

      this.$swal({
        title: this.$t("campaigns.textConfirmCreator"),
        text: this.$t("campaigns.textCreator"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("campaigns.msjConfirmCreator"),
        cancelButtonText: this.$t("campaigns.msjCancel"),
        heightAuto: false,
        customClass: {
          confirmButton: "btn confirm-button",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.rows = this.rows.filter((item) => item.uuid !== uuid);
          const influencer = [];
          influencer.push(uuid);
          const formData = new FormData();
          formData.append("influencers", influencer);
          formData.append("status", "removed");

          service.deleteCreator(this.$route.params.uuid, formData).then(() => {
            this.$swal({
              icon: "success",
              title: this.$t('campaigns.deleted_success_title'),
              text: this.$t('campaigns.deleted_success_text'),
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    updateCreator(uuid) {
      this.rows.map((item) => {
        if (item.uuid === uuid) {
          item.estimated_cost = this.creator.estimated_cost;
          item.agreed_cost = this.creator.agreed_cost;
          item.scope = this.creator.scope;
        }
      });
      const form_data = new FormData();
      form_data.append('estimated_cost', this.creator.estimated_cost);
      form_data.append('agreed_cost', this.creator.agreed_cost);
      form_data.append('scope', this.creator.scope);
      
      manage_campaign.updateInfluencer(this.$route.params.uuid, uuid, form_data).then(res => {
        this.$emit('formatHeader', res.campaign_header)
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('campaigns.updated_success'),
              icon: 'ShuffleIcon',
              variant: 'success',
              text: this.$t('campaigns.updated_success_text')
            }
          })
        })
        this.show_side = false;
      });
    },
    getContents(content) {
      let published = 0;
      content.forEach(item => {
        if (item.url_info !== null) {
          published += 1
        } else if (item.image_content !== null) published += 1
      });
      return `${published}/${content.length}`
    },
    disabledUpdateCampaign() {
      return !hasPermission("change_campaign");
    },
    addContent(content) {
      this.content_published = content.status === 'published'
      this.campaign_influencer_to_add = content.uuid
      this.show_add_content = true
      this.key_modal_add = !this.key_modal_add
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.icon-squad-network {
  width: 20px !important;
}
.vgt-table-managing {
  overflow-x: scroll;
}
.vgt-table {
  width: 100% !important;
}
.vgt-table thead {
  border-bottom: 1px solid #9eaebf !important;
}
.vgt-table th > span, .vgt-table th > div{ 
  padding-bottom: 1em;
}
.iconCheck {
  height: 18px;
}
.export-button-table {
  background-color: #f8f5fe !important;
  color: #0d1318;
  border: 1px solid #0d1318;
}
.export-button-table:disabled {
  color:  rgba(175, 175, 175, 0.8);
  border: 1px solid rgba(175, 175, 175, 0.8);
}
.name-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.name-link {
  color: black;
}
.sidebar-icon {
  font-size: 18px;
  margin-right: 10px;
}
.container-avatar-table {
  position: relative;
}
.container-avatar-table-icon {
  position: absolute; 
  bottom: -5px; 
  left: 22px;
}
.vgt-table-managing tbody tr:hover{
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  transition: all 200ms;
}
.add-content-sidebar-button {
  float: right;
  margin-top: -2em;
}
.cancel-button-swat-contents {
  border: 0 !important;
  box-shadow: none !important;
}
.cancel-button-swat-contents:focus {
  border: 0 !important;
  box-shadow: none !important;
}
</style>
